.heading {
  font-weight: 600;
  @include font-size(24px);
  @include media-breakpoint-up(lg) {
    @include font-size(40px);
  }
  @include media-breakpoint-up(xxl) {
    @include font-size(100px);
  }
}

.secondary {
  font-weight: 600;
  @include font-size(18px);
  @include media-breakpoint-up(lg) {
    @include font-size(24px);
  }
  @include media-breakpoint-up(xxl) {
    @include font-size(50px);
  }
}

.paragraph {
  @include font-size(14px);

  @include media-breakpoint-up(lg) {
    @include font-size(18px);
  }
  @include media-breakpoint-up(xxl) {
    @include font-size(35px);
  }
}

.section {
  @include padding(35px 0);
  @include media-breakpoint-up(lg) {
    @include padding(80px 0);
  }
  @include media-breakpoint-up(xxl) {
    @include padding(160px 0);
  }
}
.section_secondary_heading {
  @include padding(0 0 25px 0);
  @include media-breakpoint-up(lg) {
    @include padding(0 0 60px 0);
  }
  @include media-breakpoint-up(xxl) {
    @include padding(0 0 120px 0);
  }
}

h1 {
  @include font-size(80px);
}
