.footer-container {
  &__desc {
    color: #242a31;
    font-weight: 400;
    &:not(.first) {
      line-height: 2.5;
    }
    @include font-size(12px);

    @include media-breakpoint-up(lg) {
      @include font-size(13px);
    }
    @include media-breakpoint-up(xxl) {
      @include font-size(25px);
    }
    &.first {
      @include font-size(11px);

      @include media-breakpoint-up(lg) {
        @include font-size(12px);
      }
      @include media-breakpoint-up(xxl) {
        @include font-size(20px);
      }
    }
  }
  &__head {
    height: 94px;
    text-align: left;
    &:not(.first) {
      padding: 35px 0 0;
    }

    margin-bottom: 5px;

    font-weight: 600;
    @include font-size(12px);

    @include media-breakpoint-up(lg) {
      @include font-size(16px);
    }
    @include media-breakpoint-up(xxl) {
      @include font-size(36px);
    }
  }
  &__links {
    color: #242a31;
    font-size: 13px;
    text-decoration: none;
  }
}
