.main-banner {
  letter-spacing: 0px;
  text-align: center;
  max-width: 1700px;

  width: 100%;
  @include padding(40px 0);

  h1 {
    @include font-size(40px);
  }

  p {
    @include font-size(20px);
    @include padding(18px 0);
    max-width: 100%;
    margin: auto;
  }

  @include media-breakpoint-up(md) {
    width: 56%;
    @include padding(80px 0);

    p {
      @include padding(36px 0);
    }
  }
  @include media-breakpoint-up(lg) {
    h1 {
      @include font-size(80px);
    }
    p {
      @include font-size(18px);
    }
  }
  @include media-breakpoint-up(xxl) {
    @include padding(140px 0);
    width: 85%;
    h1 {
      @include font-size(135px);
    }
    p {
      @include font-size(36px);
    }
  }
}

.btn.play-btn {
  padding: 20px 36px 20px 65px;
  background-repeat: no-repeat;
  background-position: 15px center;
  margin: 0 10px;
  background-size: 42px;
  &__orange {
    color: #fff;
    background-image: url("../assests/play.png");
    background-color: #f59315;
  }
  &__dark {
    background-image: url("../assests/play-orange.svg");
    color: #f59315;
    background-color: transparent;
  }
  &:active,
  &:hover,
  &:visited {
    color: #fff;
    background-image: url("../assests/play.png");
    background-color: #443625;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 1000ms;
}
