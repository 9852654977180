.form-control {
  background-color: transparent;
  border: 1px solid #cdd1e0;
  border-radius: 0;
  box-shadow: none;
}
.form-label {
  color: #242a31;
  @include font-size(12px);

  @include media-breakpoint-up(lg) {
    @include font-size(16px);
  }
  @include media-breakpoint-up(xxl) {
    @include font-size(30px);
  }
}
