$grid-breakpoints: (
  xs: 0,
  sm: 360px,
  md: 768px,
  lg: 1080px,
  xl: 1440px,
  xxl: 2160px,
);

$container-max-widths: (
  sm: 360px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 2280px,
);

$rfs-breakpoint: 2160px;
$rfs-factor: 5;
$rfs-unit: "px";
$lightGrey: #242a31;
$primary: #f59315;
$light-yellow: #fffcf6;
@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap");
@import "~rfs/scss";
@import "./form.scss";
@import "./header.scss";
@import "./banner.scss";
@import "./appStore.scss";
@import "./typography.scss";
@import "./footer.scss";
svg {
  width: auto;
  height: auto;
}
img {
  max-width: 100%;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
.bg-lightyellow {
  background-color: $light-yellow;
}

.section-top-bg-banner {
  background-image: url("../assests/bg-lines.svg");
  background-color: $lightGrey;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  -webkit-animation: mymove 1.5s ease-in;
  /* Safari and Chrome */
  animation: mymove 1.5s ease-in;
}
.section-image-list {
  position: relative;
  img {
    position: relative;
    z-index: 2;
  }
  &.imageOverFlow {
    &::after {
      content: "";
      position: absolute;
      background-image: url("../assests/small-lines.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right -100px;
      display: block;
      top: 60%;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background-color: #fff;
      z-index: 1;
    }
  }
}

.section-amazing-screen {
  background-color: $light-yellow;
}

.section-whatsInApp-block {
  &__item {
    @include padding(10px 5px);
    @include media-breakpoint-up(lg) {
      @include padding(40px 35px);
    }
    @include media-breakpoint-up(xxl) {
      @include padding(80px 70px);
    }
  }
  .secondary {
    @include padding(15px 0 10px 0);
    @include media-breakpoint-up(lg) {
      @include padding(30px 0 20px 0);
    }
    @include media-breakpoint-up(xxl) {
      @include padding(60px 0 40px 0);
    }
  }
}
.section-amazing-testimonial {
  &__wrapper {
    &__marquee {
      // height: 25px;
      width: 100%;
      overflow: hidden;
      position: relative;
      &__container {
        display: block;
        width: 200%;
        overflow: hidden;
        animation: marquee 5s linear infinite;
      }
      &__item {
        float: left;
        width: 50%;
      }
    }

    @keyframes marquee {
      0% {
        // margin-left: 0;
        -moz-transform: translateX(0); /* Firefox bug fix */
        -webkit-transform: translateX(0); /* Firefox bug fix */
        transform: translateX(0);
      }
      100% {
        // margin-left: -100%;
        -moz-transform: translateX(-100%); /* Firefox bug fix */
        -webkit-transform: translateX(-100%); /* Firefox bug fix */
        transform: translateX(-100%);
      }
    }
  }
  &__content {
    border: 1px solid #f2f2f2;
    border-radius: 200px;
    padding: 15px;
    max-width: 500px;
    img {
      max-width: 64px;
    }
    @include media-breakpoint-up(xxl) {
      max-width: 830px;
      padding: 25px;
      img {
        max-width: none;
      }
    }
  }
}
.section-amazing-faqs {
  background-color: $light-yellow;
}
.section-download-app {
  img {
    max-width: 80%;
  }
}

.section-get-in-touch {
  background-color: $light-yellow;
}

#map {
  height: 100%;
  width: 100%;
  height: 650px;
  @include media-breakpoint-up(xxl) {
    height: 850px;
  }
  .leaflet-container {
    height: 100%;
    width: 100%;
  }
}
.leaflet-control-attribution {
  display: none;
}

.customAccordion {
  .accordion-item {
    border-radius: 0;
    background: #fbfafa;
    margin: 15px 0;
    border: 1px solid #f2f2f2;
  }
  .accordion-header {
    background: transparent;
  }
  .accordion-button {
    background: transparent;
    outline: none !important;
    border: 0;
    box-shadow: none;
    color: #000;
    &::after {
      display: none;
    }
    h2::before {
      width: 25px;

      @include media-breakpoint-up(xxl) {
        width: 65px;
      }
      display: inline-block;
      content: "+";
      font-size: inherit;
      padding-right: 20px;
    }
    &[aria-expanded="true"] {
      background: #fff;
      h2::before {
        content: "-";
        color: #f59315;
      }
    }
  }
  .accordion-collapse.show {
    background: #fff;
  }
  .accordion-body {
    color: #5f5f5f;
  }
}
@-webkit-keyframes mymove {
  from {
    background-position: 0% -100%;
  }
  to {
    background-position: 0% 0%;
  }
}
@keyframes mymove {
  from {
    background-position: 0% -100%;
  }
  to {
    background-position: 0% 0%;
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}

.bouncing-loader {
  animation: bouncing-loader 0.6s infinite alternate;
  background-image: url("../assests/loading-logo.png");
  width: 90px;
  height: 90px;
  background-repeat: no-repeat;
  background-size: contain;
}

.btn {
  padding: 10px 25px;
  border-radius: 0;
}
.form-control {
  padding: 15px 15px;
}

.get-in-touch {
  display: block;
  .col {
    float: left;
  }
  margin: 0;
  @include media-breakpoint-up(lg) {
    margin: 0 calc(0px - ((100vw - 103%) / 2)) 0 0;
  }
}
