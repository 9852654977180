.app-store {
  position: relative;
  z-index: 2;
  .section-amazing-screen,
  .container {
    position: relative;
    z-index: 2;
  }
  &::after {
    content: "";
    position: absolute;
    background-image: url("../assests/oval.svg");
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    top: 0;
    left: 0;
    margin: auto;
    z-index: 1;
    animation: bounce 5s linear infinite alternate;
    bottom: 75%;
    right: 80%;
  }
  // @include padding(80px 0);
  @include media-breakpoint-up(xxl) {
    // @include padding(140px 0);
    &::after {
      bottom: 76%;
      right: 90%;
    }
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
