.main-header {
  @include padding(25px 0 5px 0px);
  max-width: 2380px;
  margin: auto;
  .links {
    @include font-size(12px);
    @include padding(5px);
    @include media-breakpoint-up(lg) {
      @include font-size(18px);
      @include padding(8px 16px);
    }
    color: #fff;
    text-decoration: none;
    &.active {
      color: $primary;
    }
  }
  .pitch {
    &.active {
      color: #fff !important;
    }
  }
  .share {
    @include font-size(10px);
    @include padding(5px);
    @include media-breakpoint-up(lg) {
      @include padding(8px 16px);
      @include font-size(14px);
    }
    img {
      width: 16px;
    }
  }
  @include media-breakpoint-up(lg) {
    @include padding(25px 70px);
  }
  @include media-breakpoint-up(xxl) {
    @include padding(56px 90px);
    .links {
      @include font-size(36px);
    }
    .share {
      @include font-size(28px);
      img {
        width: 30px;
      }
    }
  }
  .navbar-brand {
    display: block;
    max-width: 130px;
    margin-bottom: 10px;
    @include media-breakpoint-up(lg) {
      max-width: 410px;
    }
  }
}
